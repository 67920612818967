import { render, staticRenderFns } from "./ToznyButton.vue?vue&type=template&id=746c2175&scoped=true&"
import script from "./ToznyButton.vue?vue&type=script&lang=js&"
export * from "./ToznyButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746c2175",
  null
  
)

export default component.exports