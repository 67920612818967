<template>
  <div>
    <img
      class="w-56 h-16 mb-12"
      alt="Tozny logo"
      src="@/assets/main-logo.svg"
    />
    <h1 class="mb-1">{{ title }}</h1>
    <h2 class="mb-6">{{ subtitle }}</h2>
    <InfoBlock
      title="TozStore"
      subtitle="Premier Encryption Storage"
      body="TozStore is a real time encryption platform.  Encrypt sensitive information directly at the browser or device level.  Retrieve it anytime to compute.  Never worry about storing PII, financial, or medical data again."
    />
    <hr class="border-white border-t-1 opacity-50 mb-6" />
    <InfoBlock
      title="TozID"
      subtitle="Secure Identity Management"
      body="Zero knowledge identity management for customer, workforce, and machine identities."
    />
    <p v-if="finePrint" class="text-tiny mt-6 mb-0">{{ finePrint }}</p>
  </div>
</template>

<script>
import InfoBlock from './InfoBlock'
export default {
  name: 'InfoPanel',
  components: {
    InfoBlock,
  },
  props: {
    title: String,
    subtitle: String,
    finePrint: String,
  },
}
</script>
<style scoped lang="scss"></style>
