<template>
  <ToznyLoader v-if="loading" class="text-tozny w-8 h-8" />
  <button v-else>{{ buttonText }}</button>
</template>

<script>
import ToznyLoader from './ToznyLoader'
export default {
  name: 'ToznyButton',
  components: {
    ToznyLoader,
  },
  props: {
    loading: Boolean,
    buttonText: String,
  },
}
</script>

<style scoped lang="scss"></style>
