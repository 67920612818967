<template functional>
  <main class="flex items-stretch min-h-screen constrained">
    <section
      class="w-3/5 tozny-gradient pt-16 lg:pt-24 px-24 lg:px-40 text-white"
    >
      <slot name="info"></slot>
    </section>
    <section class="flex-grow pt-48 lg:pt-56 px-12">
      <slot name="form"></slot>
      <p class="text-center">
        <a href="https://tozny.com/tozny-terms-of-service/">Terms Of Use</a> |
        <a href="https://tozny.com/privacy-policy/">Privacy Policy</a>
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>
