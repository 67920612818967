<template>
  <div>
    <div>
      <div class="oval"></div>
      <h3 class="font-semibold leading-tight">{{ title }}</h3>
      <h4 class="text-base font-semibold mb-6 leading-tight">{{ subtitle }}</h4>
    </div>
    <p class="mb-6">{{ body }}</p>
  </div>
</template>

<script>
export default {
  name: 'InfoBlock',
  props: {
    title: String,
    subtitle: String,
    body: String,
  },
}
</script>

<style scoped lang="scss">
.oval {
  border: 8px solid #ffffff;
  border-radius: 100%;
  height: 38px;
  width: 38px;
  float: left;
  margin: 8px 15px 0 0;
}
</style>
