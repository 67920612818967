<template>
  <DialogueBox
    data-auto-id="paper-key-dialogue"
    :showing="showing"
    title="Save This Paper Key To Protect Your Data"
  >
    <div class="p-4">
      <p>Your data cannot be decrypted without your account password.</p>
      <p>
        Securely save this paper key as a back-up in case your account password
        is ever lost.
      </p>
      <p class="font-bold">
        You risk losing access to your data without this key.
      </p>

      <dl class="mb-8 border border-2 border-error p-4">
        <dt data-auto-id="paper-key-description-term" class="font-bold mb-2">
          Account Backup Paper Key:
        </dt>
        <dd data-auto-id="paper-key-description" class="text-error font-bold">
          {{ paperKey }}
        </dd>
      </dl>
      <div class="flex items-center">
        <button
          data-auto-id="continue-button"
          @click.prevent="$emit('continue')"
          class="m-0 mr-4"
        >
          Ready to Continue
        </button>
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '@/Common/DialogueBox'
export default {
  name: 'PaperKeyDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    paperKey: String,
  },
}
</script>
