<template>
  <div
    v-bind:class="{
      'float-label': floatLabel,
      'float-active': floatActive,
      'hide-float': hideFloat,
    }"
    class="relative"
  >
    <label class="relative z-10" :for="id">{{ label }}</label>
    <div class="relative">
      <i
        v-if="isPassword"
        @click="toggleVisibility"
        class="material-icons-outlined md-24 micon password-icon"
        >{{ passwordIcon }}</i
      >
      <input
        v-bind:class="{ error, success }"
        :id="id"
        :type="dynamicType"
        :value="setValue"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToznyInput',
  props: {
    label: String,
    value: String,
    type: String,
    floatLabel: Boolean,
    hideFloat: Boolean,
    id: {
      type: String,
      required: true,
    },
    error: Boolean,
    success: Boolean,
  },
  data() {
    return {
      focus: false,
      showPassword: false,
      setValue: this.value,
    }
  },
  inheritAttrs: false,
  methods: {
    handleInput(e) {
      this.setValue = e.target.value
      this.$emit('input', e.target.value)
    },
    handleFocus() {
      this.focus = true
    },
    handleBlur() {
      this.focus = false
    },
    toggleVisibility() {
      this.showPassword = !this.showPassword
    },
  },
  computed: {
    floatActive() {
      return this.setValue !== '' || this.focus
    },
    dynamicType() {
      return this.isPassword && this.showPassword ? 'text' : this.type
    },
    isPassword() {
      return this.type === 'password'
    },
    passwordIcon() {
      return this.isPassword && this.showPassword
        ? 'visibility_on_outline'
        : 'visibility_off_outline'
    },
  },
  watch: {
    value(incomingValue) {
      this.setValue = incomingValue
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/scss/tailwind-variables.scss';
.password-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.4;
  color: $colors-gray-darkest;
}
.float-label {
  label {
    cursor: text;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.3;
    background: $colors-white;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    padding: 0 0.1em;
    transition: all 0.2s ease;
  }
  &.float-active label {
    top: 0;
    opacity: 1;
    background: $colors-white;
    transform: translate(-10%, -50%) scale(0.8);
  }
  &.float-active.hide-float label {
    opacity: 0;
  }
  input::placeholder {
    opacity: 0;
    transform: translate(5%, 0) scale(0.85);
    transition: all 0.15s;
  }
  &.float-active input::placeholder {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}
</style>
